import React from 'react';

import PageWrap from 'components/PageWrap';
import { Section } from 'components/Section';
import { PagePicTitle } from 'components/PagePicTitle';

import { FeaturePoint } from 'components/FeaturePoint';
import CallsToAction from 'components/CallsToAction';
import { Separator } from 'components/Separator';
import { SEO } from 'components/Helmets';

import ContactIcon from 'media/shared/modIcons/Contact.inline.svg';

import activityAndTasksImg from 'media/Screenshots/Quote/QuoteActivityAndTasks.png';
import builderImg from 'media/Screenshots/Quote/QuoteBuilder.png';
import PDFPreviewImg from 'media/Screenshots/Quote/QuotePDFPreview.png';
import versionsImg from 'media/Screenshots/Quote/QuoteVersions.png';

export default function ModForms({data}){
    const CTATypes = ['SignUp', 'Demo'];
    const pagePath = "/modules/quote";

    return (
        <PageWrap noSEO={true} path={pagePath}>
            <SEO
                title="The Quote Module - Mashoom"
                description="Fast and Easy Quotes and Proformas"
                previewImgKey="quote"
                path={pagePath}
            />
            <PagePicTitle
                imageCmp={ContactIcon}
                imageAlt="Contact module icon"
            >
                <h1>The Quote Module</h1>
                <p>Quotes are often the center piece of your business's income. You want them fast to build and simple to track to maximize your sales potential. We've got you covered.</p>
            </PagePicTitle>
            <FeaturePoint
                title="Can You Build A Quote Over The Phone?"
                image={builderImg}
                pageInd="1"
            >
                <p>This is our thought process in designing this module; how can we get you setup and filling in a quote as fast as possible so that you can reach to that incoming call.</p>
                <p className="paraSpaceTop">Our interface is designed with the latest web technologies giving you a fast and responsive experience. A complete set of defaults and settings allows most of the setup work to be done for you; you just need to add the lines.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="The All Important Follow Up"
                image={activityAndTasksImg}
                pageInd="2"
            >
                <p>As any experienced sales people will tell you, sent quotes should be chased, to make sure you gather feedback and of course, try to make that sale.</p>
                <p className="paraSpaceTop">Each quote can have activity and tasks logged against it to record your interactions with the customer and schedule actions that need to be taken, for instance following them up.</p>
                <p className="paraSpaceTop">These tasks and activity appear in Mission Control, giving you a centralized to do list, broken down per person as required.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="A Simple Workflow That Captures The Process"
                image={versionsImg}
                pageInd="3"
            >
                <p>Revising a quote creates a buy in from the customer; we enable you to track this process. A quote must be marked as sent before a PDF copy can be downloaded. It can then either be accepted or rejected. A rejected quote can then have a new version created and so forth.</p>
                <p className="paraSpaceTop">This simple workflow means that you can keep track of how a quote has progressed to give you and your customer the complete picture.</p>
            </FeaturePoint>
            <Separator />
            <FeaturePoint
                title="Proformas, Multiple Currencies, Discounts, Standard Charges..."
                image={PDFPreviewImg}
                pageInd="4"
            >
                <p>... and much more. Our module has all the features you need to build full quotes with a complete set of information.</p>
                <p className="paraSpaceTop">Whilst providing this, we understand that sometimes you only need the basics. Our PDF generation engine react to this and makes sure you quote looks good with only the basics or every bit of information utilized.</p>
            </FeaturePoint>
            <Section id="FeatureCTA">
                <div id="FeatureCTACont">
                    <h2>Like what your hear?</h2>
                    <CallsToAction types={CTATypes}></CallsToAction>
                </div>
            </Section>
        </PageWrap>
    );
}